angular.module('app')
    .controller('travelPolicyRemovalModalController',
        ["$scope", "$log", "$uibModalInstance", "message", function ($scope, $log, $uibModalInstance, message) {
            var vm = this;
            vm.message = message;
            vm.removeTravelPolicy = removeTravelPolicy;

            function removeTravelPolicy() {
                $uibModalInstance.close();
            }

        }]);